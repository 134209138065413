import React, { useState } from "react"
import { Helmet } from "react-helmet"
import {
  NavBar,
  Footer,
} from '../components'
import {
  H1,
  FeatureHeroBg,
  Container,
  ContactForm,
  Button
} from '../styles/components'
import logo from '../images/logo_black.png'

export default function ContactPage() {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = event => {
    event.preventDefault()
    setSubmitted(true);
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  };

  return (
    <div className="contactPage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Turnkey | Contact</title>
        <link rel="canonical" href="https://turnkey-egy.com/contact" />
      </Helmet>
      <NavBar activePage="contact" background="white" logo={logo} />
      {submitted ?
      <div style={{ height: "100vh" }}>
        <div className="contactBackground">
          <FeatureHeroBg>
            <section>
              <H1 center>Thank you for reaching out!</H1>
              <p>Your message has been received and a member of our team will reach out to you soon.</p>
            </section>
          </FeatureHeroBg>
          <Container></Container>
        </div>
      </div> :
      <>
        <div className="contactBackground">
          <FeatureHeroBg>
            <section>
              <H1 center>Get in Touch</H1>
              <p>We would love to bring your vision to life.</p>
            </section>
          </FeatureHeroBg>
        </div>
        <Container>
          <ContactForm
            name="Contact"
            method="POST"
            data-netlify="true"
            id="contactForm"
          >
            <h4>Contact Us</h4>
            <input type="hidden" name="form-name" value="Contact" />
            <div>
              <input
                placeholder="Full Name"
                aria-label="Full Name"
                type="text"
                name="Full Name"
                required
              />
            </div>
            <div>
              <input
                placeholder="Email"
                aria-label="Email"
                type="email"
                name="Email"
                required
              />
            </div>
            <div>
              <input
                placeholder="Phone Number (optional)"
                aria-label="Phone Number"
                type="tel"
                name="Phone"
              />
            </div>
            <textarea
              placeholder="Message"
              name="Message"
              form="contactForm"
              required
            ></textarea>
            <Button mt={3} type="submit">
              Submit
            </Button>
          </ContactForm>
        </Container>
      </>}
      <Footer />
    </div>
  )
}
